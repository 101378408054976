<template>
  <div>
    <div class="topbasic">
      <div class="tbtitle">基本信息</div>
      <div class="tbcont">
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            :model="formLabelAlign"
            label-width="120px"
          >
            <el-form-item label="账户：">
              <span>{{ user.username }}</span>
            </el-form-item>
            <el-form-item label="账户权限：">
              <span v-if="user.roles">{{ user.roles.join(",") }}</span>
              <span v-if="user.fid!=''">子账户</span>
            </el-form-item>
            <el-form-item>
              <el-button @click="passwordVisible = true">修改密码</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <el-form
            ref="form"
            :label-position="labelPosition"
            :model="formLabelAlign"
            label-width="120px"
          >
            <el-form-item label="联系电话：">
              <div v-if="!edit_phone">
                <span>{{ user.telephone }}</span>
                <i class="el-icon-edit" @click="edit_phone = true"></i>
              </div>
              <div v-else>
                <el-input v-model="user.telephone"></el-input>
                <i class="el-icon-check" @click="editSelf"></i>
              </div>
            </el-form-item>
            <el-form-item label="用户名：">
              <div v-if="!edit_username">
                <span>{{ user.name }}</span>
                <i class="el-icon-edit" @click="edit_username = true"></i>
              </div>
              <div v-else>
                <el-input v-model="user.name"></el-input>
                <i class="el-icon-check" @click="editSelf"></i>
              </div>
            </el-form-item>
          </el-form>
        </div>
        <div class="account">
          <div v-if="user.fid || user.fid != null || user.fid != ''">
            <div
              v-if="fileList1 && fileList1.length > 0"
              class="uploadfile"
            >
              <img style="width: 100%; height: 100%" :src="fileList1[0].url" />
            </div>
          </div>
          <div v-else>
            <el-upload
              action="https://api.gbservice.cn/api/public/bupload/"
              class="upload-demo"
              drag
              :file-list="fileList1"
              :on-success="fileUploadSuccess1"
              :show-file-list="false"
            >
              <div
                v-if="fileList1 && fileList1.length > 0"
                style="height: 100%"
              >
                <img
                  style="width: 100%; height: 100%"
                  :src="fileList1[0].url"
                />
              </div>
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">上传营业执照</div>
                <div class="el-upload__text">
                  将文件拖到此处，或<em>点击上传</em>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
        <div class="account">
          <div v-if="user.fid || user.fid != null || user.fid != ''">
            <div
              v-if="fileList2 && fileList2.length > 0"
              style="
                display: flex;
                align-items: center;
                justify-content: center;
              "
              class="uploadfile"
            >
              <img
                style="width: 30%"
                src="https://cloud.cdmp.tech/088df95e073750ac751d3b927e0c8a6a.png"
              />
              <div style="color: #66b1ff">证书已上传</div>
            </div>
          </div>
          <div v-else>
            <el-upload
              action="https://api.gbservice.cn/api/public/bupload/"
              class="upload-demo"
              drag
              :show-file-list="false"
              :file-list="fileList2"
              :on-success="fileUploadSuccess2"
            >
              <div
                v-if="fileList2 && fileList2.length > 0"
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  height: 100%;
                "
              >
                <img
                  style="width: 30%"
                  src="https://cloud.cdmp.tech/088df95e073750ac751d3b927e0c8a6a.png"
                />
                <div style="color: #66b1ff">证书已上传</div>
              </div>
              <div v-else>
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">能效测评相关证书</div>
                <div class="el-upload__text">请将能效测评相关证书打包上传</div>
              </div>
            </el-upload>
          </div>
        </div>
      </div>
    </div>
    <div class="testtable">
      <div class="tttotle">
        <div class="ttleft">测评人员名单</div>
        <div class="ttright">
          <el-button type="primary" @click="dialogVisible = true"
            >添加
          </el-button>
        </div>
      </div>
      <div class="ttcontent">
        <el-table :data="tableData" stripe style="width: 100%">
          <el-table-column align="center" label="姓名" prop="name">
          </el-table-column>
          <el-table-column
            align="center"
            label="联系方式"
            min-width="180"
            prop="tel"
          ></el-table-column>
          <el-table-column align="center" label="职称" prop="work">
          </el-table-column>
          <el-table-column align="center" label="类型" prop="job">
           <template slot-scope="{ row }">
              <span v-if="row.type==1">测评人员</span>
              <span v-if="row.type==2">审核人员</span>
              <span v-if="row.type==3">批准人员</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="证书" prop="file">
            <template slot-scope="{ row }">
              <span v-if="row.file">证书已上传</span>
              <span v-else>证书未上传</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="操作" style="color: red">
            <template slot-scope="{ row }">
              <div style="cursor: pointer">
                <i
                  class="el-icon-delete"
                  style="color: #f56c6c"
                  @click="delStaff(row)"
                ></i
                ><i
                  class="el-icon-edit"
                  style="color: #e6a23c"
                  @click="editStaff(row)"
                ></i>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <div class="pageTotal">
        <div class="block">
          <el-pagination
            :current-page="page"
            :page-count="totalPage"
            :page-size="limit"
            :total="totalCount"
            background
            layout=" prev, pager, next, total, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogVisible" title="新增测评人员" width="30%">
      <div class="cptest">
        <el-form ref="form" :model="form" label-width="100px">
          <el-form-item label="姓名">
            <el-input v-model="form.name"></el-input>
          </el-form-item>
          <el-form-item label="联系方式">
            <el-input v-model="form.tel"></el-input>
          </el-form-item>
          <el-form-item label="类型">
            <el-select v-model="form.type" placeholder="请选择类型">
              <el-option label="测评人员" value="1"></el-option>
              <el-option label="审核人员" value="2"></el-option>
              <el-option label="批准人员" value="3"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="职称">
            <el-input v-model="form.work"></el-input>
          </el-form-item>
          <el-form-item label="职务">
            <el-input v-model="form.job"></el-input>
          </el-form-item>
          <el-form-item label="证书">
            <el-upload
              :file-list="fileList3"
              :on-success="fileUploadSuccess3"
              action="https://api.gbservice.cn/api/public/bupload/"
              class="upload-demo"
            >
              <el-button size="small" type="primary">点击上传</el-button>
            </el-upload>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addStaff">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog :visible="passwordVisible" title="修改密码">
      <div>
        <el-form
            ref="modifyPasswd"
            :model="modifyPasswdForm"
            label-width="120px"
            :rules="modifyPasswdRules"
        >
          <el-form-item prop="oldpassword" label="旧密码：">
            <el-input v-model="modifyPasswdForm.oldpassword"></el-input>
          </el-form-item>
          <el-form-item prop="password" label="新密码：">
            <el-input v-model="modifyPasswdForm.password" placeholder="密码格式：英文大小写+符号+数字"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="passwordVisible = false">取 消</el-button>
        <el-button type="primary" @click="modifyPasswd">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import {
  editSelfApi,
  staffDelApi,
  staffListApi,
  staffStoreApi,
  userInfoApi,
} from "../../../../api/project";

export default {
  data() {
    return {
      passwordVisible: false,
      dialogVisible: false,
      form: {},
      tableData: [],
      labelPosition: "right",
      formLabelAlign: {},

      page: 1,
      totalPage: 0,
      limit: 10,
      totalCount: 0,

      user: {},

      edit_phone: false,
      edit_username: false,

      modifyPasswdForm: {},
      modifyPasswdRules: {
        oldpassword: [
          { required: true, message: '请填写旧密码', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请填写新密码', trigger: 'blur' }
        ],
      },

      fileList1: [],
      fileList2: [],
      fileList3: [],
    };
  },
  mounted() {
    console.log("用户信息：", this.user);
    this.getStaffList();
    this.getUserInfo();
  },
  methods: {
    modifyPasswd () {
      this.$refs.modifyPasswd.validate((valid) =>{
        if (valid){
          // let params = Object.assign(this.user,this.modifyPasswdForm);
          let params = Object.assign({},this.modifyPasswdForm);
          params.token = this.$store.state.token;
            params.id= this.$store.state.user.id;
          editSelfApi(params).then(res =>{
            console.log(res);
            this.$message.success("修改成功");
            this.passwordVisible = false;
            this.$refs.modifyPasswd.resetFields();
          })
        }
      })

    },
    //获得主账号信息
    getUserInfo() {
      let params = {
        token: this.$store.state.token,
      };
      let that = this;
      userInfoApi(params).then((res) => {
        console.log(res);
        that.user = res.data;
        if (that.user.photo) {
          that.fileList1.push({
            url: that.user.photo,
            name: "营业执照",
          });
        }
        if (that.user.book) {
          that.fileList2.push({
            url: that.user.book,
            name: "测评相关证书",
          });
        }
      });
    },

    //编辑信息
    editSelf() {
      let params = {
        token: this.$store.state.token,
        id: this.$store.state.user.id,
      };
      if (this.edit_phone) {
        this.edit_phone = false;
        params.telephone = this.user.telephone;
      }
      if (this.edit_username) {
        this.edit_username = false;
        params.name = this.user.name;
      }
      let that = this;
      editSelfApi(params).then((res) => {
        console.log(res);
        that.$message.success("修改成功");
      });
    },

    //新增员工
    addStaff() {
      let params = Object.assign({}, this.form);
      if (this.fileList && this.fileList.length > 0) {
        params.file = this.fileList[0].url;
      }
      params.token = this.$store.state.token;
      let that = this;
      staffStoreApi(params).then((res) => {
        console.log(res);
        that.dialogVisible = false;
        that.getStaffList();
        if (params.id) {
          that.$message.success("修改成功");
        } else {
          that.$message.success("新增成功");
        }
      });
    },
    // 编辑员工
    editStaff(row) {
      this.dialogVisible = true;
      this.form = Object.assign({}, row);
    },
    //删除员工
    delStaff(row) {
      this.$confirm("此操作将永久删除该成员，是否继续？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            token: this.$store.state.token,
            id: row.id,
          };
          let that = this;
          staffDelApi(params).then((res) => {
            console.log(res);
            this.$message.success("删除成功");
            // that.$message.success(res)
            that.getStaffList();
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },

    //获得员工列表
    getStaffList() {
      let params = {
        page: this.page,
        limit: this.limit,
        token: this.$store.state.token,
      };
      let that = this;
      staffListApi(params).then((res) => {
        console.log(res);
        that.tableData = res.data;
        that.page = res.page;
        that.pageCount = res.pageCount;
        that.totalCount = res.totalCount;
      });
    },

    //增加员工文件上传成功回调
    fileUploadSuccess1(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList1 = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList1);
      if (this.fileList1 && this.fileList1.length > 0) {
        let params = {
          photo: this.fileList1[0].url,
          token: this.$store.state.token,
          id: this.$store.state.user.id,
        };
        let that = this;
        editSelfApi(params).then((res) => {
          console.log(res);
          that.$message.success("修改成功");
        });
      }
    },

    handleRemove(file, fileList) {
      if (fileList.length > 0) {
        this.fileList1 = fileList.map((item) => {
          return item.response.data;
        });
      } else {
        this.fileList1 = [];
      }
    },

    //增加员工文件上传成功回调
    fileUploadSuccess2(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList2 = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList2);
      if (this.fileList2 && this.fileList2.length > 0) {
        let params = {
          book: this.fileList2[0].url,
          token: this.$store.state.token,
          id: this.$store.state.user.id,
        };
        let that = this;
        editSelfApi(params).then((res) => {
          console.log(res);
          that.$message.success("修改成功");
        });
      }
    },

    //增加员工文件上传成功回调
    fileUploadSuccess3(response, file, fileList) {
      console.log(fileList);
      let tmpList = fileList.slice(fileList.length - 1);
      this.fileList = tmpList.map((item) => {
        return item.response.data;
      });
      console.log(this.fileList);
      if (this.fileList && this.fileList.length > 0) {
        let params = {
          photo: this.fileList[0].url,
          token: this.$store.state.token,
          id: this.$store.state.user.id,
        };
        let that = this;
        editSelfApi(params).then((res) => {
          console.log(res);
          that.$message.success("修改成功");
        });
      }
    },

    handleSizeChange(limit) {
      this.limit = limit;
      this.getStaffList();
    },
    handleCurrentChange(page) {
      this.page = page;
      this.getStaffList();
    },
  },
};
</script>
<style scoped>
/deep/ .el-dialog__headerbtn .el-dialog__close {
  color: #ffffff;
}

/deep/ .el-dialog__title {
  color: #ffffff;
}

/deep/ .el-dialog {
  border-radius: 10px;
}

/deep/ .el-dialog__header {
  background-color: #3086fb;
  font-weight: bold;
  border-radius: 5px 5px 0 0;
}

/deep/ .el-form-item__content > span {
  font-size: 18px;
  font-weight: normal;
  color: #333333;
}

/deep/ .el-form-item__label {
  font-size: 18px;
  font-weight: normal;
  color: #999999;
}

/deep/ .el-upload-dragger {
  border: 1px dashed #3086fb;
}

/deep/ .el-upload-dragger .el-icon-upload {
  margin: 18px 0 16px;
  color: #3086fb;
}

/deep/ .el-upload-dragger .el-upload__text {
  color: #3086fb;
}

/deep/ .el-upload-dragger {
  width: 261px;
  height: 140px;
}

/deep/ .el-icon-edit {
  color: #3086fb;
  margin-left: 10px;
}
</style>
<style lang="scss" scoped>
.uploadfile {
  width: 261px;
  height: 140px;
  border: 1px dashed #3086fb;
}
.topbasic {
  margin: 30px 0;
  padding: 20px 50px;
  height: 230px;
  background: #ffffff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.08);

  .tbtitle {
    margin: 22px 50px;
    font-size: 18px;
    color: #333333;
  }

  .tbcont {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.testtable {
  margin-bottom: 20px;

  .tttotle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;

    .ttleft {
      font-size: 18px;
      font-weight: normal;
      color: #333333;
      padding-left: 40px;
    }
  }

  .ttcontent {
    background-color: #ffffff;
  }

  .pageTotal {
    margin-top: 37px;
    display: flex;
    justify-content: center;
  }
}
</style>
